import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as blogindex from './blogindex.module.scss'

const BlogPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulBlogPost (
      sort:{
        fields:publishedDate,
        order:DESC
      }
    ){
      edges {
        node {
          title,
          slug,
          publishedDate(fromNow: true),
          linkImage {
            title,
            gatsbyImageData
          }
        }
      }
    }
  }
`)

const imageFormatter = (linkImage) => {
  const alt = linkImage.title;
  const image = getImage(linkImage.gatsbyImageData);
  return <GatsbyImage alt={alt} image={image}/>
}

  const blogItems = data.allContentfulBlogPost.edges;
  
  return <Layout expand>
    <ul className={blogindex.index}>
      {blogItems.map(item => <li key={item.node.slug}>
        <Link to={`/blog/${item.node.slug}`}>
          <section class={blogindex.articleContainer}>
            <article>
              {imageFormatter(item.node.linkImage)}
              <h1>{item.node.title}</h1>
              <p>{item.node.publishedDate}</p>
            </article>
          </section>
        </Link>
      </li>)}
    </ul>
  </Layout>
}

export default BlogPage
export const Head = () => (
  <SEO title="Blog" />
  )